/* global $, objectFitImages */
import $ from 'jquery';

$(() => {
  const $jumbotron = $('.jumbotron');

  $(window).scroll(() => {
    const scrollTop = $(window).scrollTop();
    const jHeight = $jumbotron.height();
    const offset = (jHeight - scrollTop);
    const factor = offset / jHeight;

    if(factor > 0) {
      $jumbotron.css({'opacity': factor});
    }
  })
});
