import $ from 'jquery';

$(() => {
    let menuIconTrigger = document.querySelector('.menu-icon-trigger');
    let menuTrigger = document.querySelector('.menu-trigger');
    const navLinks = document.querySelector('.nav-links');
    const menu = document.querySelector('.menu');
    const closeMenuButton = document.getElementById('menu-trigger-close');
    let menuClosed = true;

    function noScroll() {
      window.scrollTo(0, 0);
    }

    function toggleMenu() {
      if (menuClosed) {
        menu.classList.add('open');
        window.addEventListener('scroll', noScroll);
        menuIconTrigger.classList.add('hidden');
        setTimeout(() => {
          $('html').css({overflow: 'hidden'});
        }, 500);
        menuClosed = false;
      } else {
        menu.classList.remove('open');
        window.removeEventListener('scroll', noScroll);
        $('html').css({overflow: 'scroll'});
        menuClosed = true;
      }
    }

    menuIconTrigger.addEventListener('click', () => {
      toggleMenu();
    });
    menuTrigger.addEventListener('click', () => {
      toggleMenu();
    });
    closeMenuButton.addEventListener('click', () => {
      menu.classList.remove('open');
      navLinks.classList.remove('hidden');
      menuIconTrigger.classList.remove('hidden');
      $('html').css({overflow: 'scroll'});
      window.removeEventListener('scroll', noScroll);
      menuClosed = true;
    });
});